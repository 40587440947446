export const GetTitleFromShowTitle = (showTitle: string | undefined): string => {
	return showTitle === undefined ? "" : showTitle.lastIndexOf("(") == -1 ? showTitle : showTitle.substring(0, showTitle.lastIndexOf("(") - 1);
};

export const ConvertToProperCase = (s: string | undefined): string => {
	if (s === undefined) {
		return "";
	}
	return s
		.toLowerCase()
		.split(" ")
		.map(function (word: string) {
			return word.charAt(0).toUpperCase() + word.slice(1);
		})
		.join(" ");
};

export const GetColorFromRating = (rating: number | null): string => {
	if (!rating) {
		return "inherit";
	}
	var ratingRounded = Math.round(rating);
	switch (ratingRounded) {
		case 10:
			return "#094782";
		case 9:
			return "#53769E";
		case 8:
			return "#9BB1C9";
		case 7:
			return "#C5AC51";
		case 6:
			return "#B88F32";
		case 5:
			return "#A4590A";
		default:
			return "#6C2200";
	}
};
export const GetTextColorFromRating = (rating: number | null): string => {
	if (!rating) {
		return "inherit";
	}
	var ratingRounded = Math.round(rating);
	switch (ratingRounded) {
		case 10:
		case 9:
		case 8:
		case 7:
		case 6:
		case 5:
			return "#ffffff";
		default:
			return "#ffffff";
	}
};
