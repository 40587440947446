import {useTheme} from "@mui/material/styles";
import {Divider, Typography} from "@mui/material";
import {LineChart, XAxis, YAxis, Tooltip, Legend, Line} from "recharts";
import {SeasonObject, ShowObject} from "../Interfaces/Api";
import {GetColorFromRating} from "../Tools/Tools";
import Grid2 from "@mui/material/Unstable_Grid2";

export function SeasonLine(props: {show: ShowObject; width: number}) {
	const {
		show: {seasons, TotalAverage, MinRating},
		width,
	} = props;
	const theme = useTheme();

	const lineChartContent = (props: any): any => {
		const {payload, label} = props;
		let title: string = (label && label.replace("S", "")) ?? "";
		return (
			<Grid2
				container
				spacing={5}
				sx={{
					p: 2,
					backgroundColor: theme.palette.background.default,
					borderWidth: "2px",
					borderStyle: "solid",
					borderColor: theme.palette.text.primary,
					width: 320,
				}}
			>
				<Grid2 xs={12}>
					<Typography sx={{textAlign: "center", p: 2, fontSize: "large"}}>{`Season ${title}`}</Typography>
					<Divider />
				</Grid2>
				{payload?.map((item: any) => {
					return (
						<Grid2 xs={12}>
							<Typography>{`${item.name}: ${item.value}`}</Typography>
						</Grid2>
					);
				})}
			</Grid2>
		);
	};
	const customLineChartDot = (props: any): any => {
		const {cx, cy, value} = props;
		let isGood = Math.round(value * 10) / 10 >= TotalAverage;
		const rc = GetColorFromRating(Math.round(value));

		if (isGood) {
			return (
				<svg x={cx - 10} y={cy - 10} width={20} height={20} fill='red' viewBox='0 0 1024 1024'>
					<circle r={500} cx='50%' cy='50%' fill={rc} strokeWidth={0.2} stroke={theme.palette.background.default} />
				</svg>
			);
		} else {
			return (
				<svg x={cx - 10} y={cy - 10} width={20} height={20} fill='red' viewBox='0 0 1024 1024'>
					<circle r={500} cx='50%' cy='50%' fill={rc} strokeWidth={0.2} stroke={theme.palette.background.default} />
				</svg>
			);
		}
	};

	return (
		<LineChart width={width * 0.9} height={250} data={seasons} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
			<XAxis
				tick={{fill: theme.palette.text.primary}}
				dataKey={(season: SeasonObject) => {
					return `S${season.Season}`;
				}}
			/>
			<YAxis
				tick={{fill: theme.palette.text.primary}}
				dataKey={(season: SeasonObject) => {
					return season.Rating;
				}}
				domain={[MinRating, 10]}
			/>
			<Tooltip
				content={lineChartContent}
				contentStyle={{backgroundColor: theme.palette.background.default}}
				itemStyle={{color: theme.palette.text.primary}}
			/>
			<Legend />
			<Line
				type='monotone'
				name='Season Rating'
				dataKey={(season: SeasonObject) => {
					return season.Rating.toFixed(1);
				}}
				strokeWidth={3}
				dot={customLineChartDot}
				stroke={theme.palette.primary.main}
			/>
			<Line
				name='Series Average'
				type='monotone'
				dataKey={() => {
					return TotalAverage.toFixed(1);
				}}
				strokeWidth={1}
				stroke={GetColorFromRating(Math.round(TotalAverage))}
			/>
		</LineChart>
	);
}
