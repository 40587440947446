import {Card, CardHeader, Avatar, Typography, CardMedia, Button, CardActions, CardContent, CircularProgress} from "@mui/material";
import {useEffect, useState} from "react";
import {GenreListItem, OmdbShow, RandomShowObject, ShowObject} from "../Interfaces/Api";
import useRunOnce from "../Tools/RunOnce";
import {GetColorFromRating, GetTitleFromShowTitle} from "../Tools/Tools";

export interface IGenreTopThreeCardProps {
	item: GenreListItem;
	index: number;
}
export function GenreTopThreeCard(props: IGenreTopThreeCardProps): JSX.Element {
	const {item: genreItem, index} = props;
	const [isLoadingShow, setIsLoadingShow] = useState<boolean>(true);
	const [show, setShow] = useState<ShowObject>();
	const [omdbShow, setOmdbShow] = useState<OmdbShow>();
	const [showImage, setShowImage] = useState<string>("");

	useRunOnce({
		fn: () => {
			//#region LOAD SHOW
			setIsLoadingShow(true);

			fetch("/api/gettitle.php", {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify({show: genreItem.cleantitle}),
			})
				.then((response) => response.json())
				.then((data: RandomShowObject) => {
					fetch("/api/gettitle.php", {
						method: "POST",
						headers: {"Content-Type": "application/json"},
						body: JSON.stringify({show: data.CleanTitle}),
					})
						.then((response) => response.json())
						.then((showData: ShowObject) => {
							setShow(showData);
							fetch(`https://www.omdbapi.com/?i=${showData.Id}&apikey=da6a49dd`, {method: "GET"})
								.then((response) => response.json())
								.then((data: OmdbShow) => {
									setOmdbShow(data);
								})
								.catch((e) => {
									setShowImage("/images/noImage.png");
								});
						});
				})
				.finally(() => {
					setIsLoadingShow(false);
				});
			//#endregion
		},
	});

	useEffect(() => {
		if (omdbShow && omdbShow.Poster) {
			setShowImage(omdbShow.Poster);
		}
	}, [omdbShow]);

	return isLoadingShow ? (
		<CircularProgress color='secondary' />
	) : (
		genreItem && (
			<Card>
				<CardHeader
					avatar={
						<Avatar sx={{backgroundColor: GetColorFromRating(genreItem.rating)}} aria-label='rating'>
							{`#${index + 1}`}
						</Avatar>
					}
					title={
						<Typography variant='h3'>
							{omdbShow ? omdbShow.Title : show ? `${GetTitleFromShowTitle(show.ShowTitle)}` : `${GetTitleFromShowTitle(genreItem.title)}`}
						</Typography>
					}
				></CardHeader>
				<CardMedia component='img' width='500' height='261' image={showImage}></CardMedia>
				<CardContent sx={{height: "8.5rem", overflow: "hidden"}}>
					<Typography variant='body1'>{`${omdbShow?.Plot ?? "No plot details available"}`}</Typography>
				</CardContent>
				<CardActions>
					<Button component='a' href={`/${show?.CleanTitle ?? genreItem.cleantitle}`} variant='text'>
						View
					</Button>
				</CardActions>
			</Card>
		)
	);
}
