import {Container, Backdrop, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Link} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useEffect, useState} from "react";
import {SteadyTopThreeCard} from "../../Components/SteadyTopThree";
import {SteadyListItem} from "../../Interfaces/Api";
import useRunOnce from "../../Tools/RunOnce";

export default function Steady(): JSX.Element {
	const [listItems, setListItems] = useState<SteadyListItem[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [topThree, setTopThree] = useState<SteadyListItem[]>([]);

	useRunOnce({
		fn: () => {
			//#region LOAD SHOW
			setIsLoading(true);

			fetch("/api/lst_steady.php", {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: null,
			})
				.then((response) => response.json())
				.then((data: SteadyListItem[]) => {
					setListItems(data);
				})
				.finally(() => {
					setIsLoading(false);
				});
			//#endregion
		},
	});

	useEffect(() => {
		if (listItems) {
			setTopThree(listItems.slice(0, 3));
		}
	}, [listItems]);

	return (
		<Container maxWidth='lg'>
			{isLoading ? (
				<Backdrop open={true} sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}>
					<CircularProgress color='secondary' />
				</Backdrop>
			) : (
				listItems &&
				listItems.length > 0 && (
					<Grid2 container spacing={2}>
						<Grid2 xs={12}>
							<Typography variant='h1' sx={{my: 2, textAlign: "center"}}>
								Steady
							</Typography>
							<Typography variant='h2' sx={{textAlign: "center"}}>
								These shows never dipped below a rating of 8.5 for any season.
							</Typography>
						</Grid2>
						<Grid2 xs={12}>
							<Grid2 container spacing={3}>
								{topThree &&
									topThree.length > 0 &&
									topThree.map((gi: SteadyListItem, i: number) => {
										return (
											<Grid2 xs={12} md={4} key={`topThree${i}`}>
												<SteadyTopThreeCard item={gi} index={i}></SteadyTopThreeCard>
											</Grid2>
										);
									})}
							</Grid2>
						</Grid2>
						<Grid2 xs={12}>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Rank</TableCell>
											<TableCell>Title</TableCell>
											<TableCell>Season Average Rating</TableCell>
											<TableCell>Lowest Season Rating</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{listItems.map((gi: SteadyListItem, index: number) => {
											return (
												<TableRow key={`genreRow${index}`}>
													<TableCell>{index + 1}</TableCell>
													<TableCell>
														<Link href={`/${gi.cleantitle}`} underline='hover' target='_self' color='inherit'>
															{gi.title}
														</Link>
													</TableCell>
													<TableCell>{gi.seasonaverage.toFixed(1)}</TableCell>
													<TableCell>{gi.lowestseason.toFixed(1)}</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid2>
					</Grid2>
				)
			)}
		</Container>
	);
}
