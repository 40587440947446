import {Box, Chip, Typography} from "@mui/material";
import {GetColorFromRating} from "../Tools/Tools";

export default function ColorKey(): JSX.Element {
	return (
		<Box
			component='div'
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignContent: "space-around",
			}}
		>
			<Chip sx={{mr: 2, backgroundColor: GetColorFromRating(4)}} label='0-4' />
			<Chip sx={{mr: 2, backgroundColor: GetColorFromRating(5)}} label='5' />
			<Chip sx={{mr: 2, backgroundColor: GetColorFromRating(6)}} label='6' />
			<Chip sx={{mr: 2, backgroundColor: GetColorFromRating(7)}} label='7' />
			<Chip sx={{mr: 2, backgroundColor: GetColorFromRating(8)}} label='8' />
			<Chip sx={{mr: 2, backgroundColor: GetColorFromRating(9)}} label='9' />
			<Chip sx={{mr: 2, backgroundColor: GetColorFromRating(10)}} label='10' />
		</Box>
	);
}
