import {Card, CardHeader, CardMedia, Link, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Container} from "@mui/system";
import {Helmet} from "react-helmet-async";

export default function GenreList(): JSX.Element {
	const genres: string[] = [
		"Action",
		"Adventure",
		"Animation",
		"Biography",
		"Comedy",
		"Crime",
		"Documentary",
		"Drama",
		"Family",
		"Fantasy",
		"History",
		"Horror",
		"Mystery",
		"Romance",
		"Sci-Fi",
		"Sport",
		"Thriller",
		"War",
	];

	return (
		<Container maxWidth={false}>
			<Grid2
				container
				spacing={3}
				sx={{
					my: {xs: 3},
					display: "flex",
					flexGrow: 1,
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{genres &&
					genres.map((genre: string) => {
						return (
							<Grid2 sx={{margin: {lg: 2, xs: 1}}} xs={12} md={4} lg={3} xl={2} key={genre}>
								<Link href={`/genres/${genre}`}>
									<Card>
										<CardHeader title={<Typography variant='h3'>{genre}</Typography>} />
										<CardMedia component='img' width='500' height='261' image={`/images/${genre}.jpg`}></CardMedia>
									</Card>
								</Link>
							</Grid2>
						);
					})}
			</Grid2>
		</Container>
	);
}
