import {ToggleOff, ToggleOn} from "@mui/icons-material";
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import {ShowObject} from "../Interfaces/Api";
import {GetColorFromRating} from "../Tools/Tools";
import {ShowToolTip} from "./ShowTooltip";
export interface IHeatMapProps {
	showObject: ShowObject;
	isPrint?: boolean;
}
export function HeatMap(props: IHeatMapProps): JSX.Element {
	const {showObject, isPrint} = props;
	const uniqueSeasons = [...new Set(showObject.seasons.map((s) => s.Season))];
	const uniqueEpisodes = [...new Set(showObject.episodes.map((s) => s.Episode))];
	const [showTitles, setShowTitles] = useState<boolean>(true);
	const [isCompressed, setIsCompressed] = useState<boolean>(false);

	useEffect(() => {
		setIsCompressed(!showTitles);
	}, [showTitles]);

	const toggleTitles = (): void => {
		setShowTitles(!showTitles);
	};

	return (
		<TableContainer component={Paper}>
			<Table sx={{textAlign: "center"}}>
				<TableHead>
					<TableRow>
						<TableCell sx={{borderBottom: 0, width: "3rem", maxWidth: "3rem", textAlign: "center", alignContent: "center"}}>
							{!isPrint && (
								<IconButton onClick={toggleTitles} sx={{p: 0, m: 0}} aria-label={showTitles ? "toggleOn" : "toggleOff"}>
									{showTitles ? <ToggleOn></ToggleOn> : <ToggleOff></ToggleOff>}
								</IconButton>
							)}
						</TableCell>
						{uniqueSeasons.sort().map((season: string) => {
							return (
								<TableCell sx={{borderBottom: 0, m: 0, p: 0, textAlign: "center", alignContent: "center"}} key={`SeasonHeaderCell${season}`}>
									{!isCompressed && `S${season}`}
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{uniqueEpisodes.sort().map((ep: string) => {
						return (
							<TableRow key={`eprow${ep}`}>
								<TableCell sx={{borderBottom: 0, textAlign: "center", alignContent: "center"}}>{!isCompressed && `E${ep}`}</TableCell>
								{uniqueSeasons.sort().map((season: string) => {
									return (
										<TableCell
											key={`se${ep}${season}`}
											sx={{
												cursor: showObject.episodes.filter((e) => e.Episode === ep && e.Season === season)[0] ? "pointer" : "inherit",
												borderBottom: 0,
												textAlign: "center",
												alignContent: "center",
												m: !isCompressed ? "inherit" : 0,
												p: !isCompressed ? "inherit" : 0,
												backgroundColor: GetColorFromRating(
													showObject.episodes.filter((e) => e.Episode === ep && e.Season === season)[0]?.Rating
												),
											}}
										>
											<ShowToolTip
												hideRating={isCompressed}
												episodeObject={showObject.episodes.filter((e) => e.Episode === ep && e.Season === season)[0]}
											></ShowToolTip>
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
					<TableRow></TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}
