import {Box, Button, CircularProgress, Container, Paper, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid version 2
import {useState} from "react";
import {ShowObject, RandomShowObject} from "../Interfaces/Api";
import {GetTitleFromShowTitle} from "../Tools/Tools";
import useRunOnce from "../Tools/RunOnce";

export default function Home(): JSX.Element {
	const [isLoadingCompare, setIsLoadingCompare] = useState<boolean>();
	const [isLoadingRandom, setIsLoadingRandom] = useState<boolean>();
	const [randomShowTopVoted, setRandomShowTopVoted] = useState<ShowObject>();
	const [compareShows, setCompareShows] = useState<ShowObject[]>([]);
	const hookText: string[] = [
		"Should I watch the last season of 'SHOWNAME'?",
		"Is 'SHOWNAME' worth watching?",
		"How long has 'SHOWNAME' been on the air?",
		"Does 'SHOWNAME' get better?",
		"How many 'SHOWNAME' seasons are there?",
		"What is the best episode of 'SHOWNAME'?",
		"What is the best season of 'SHOWNAME'?",
	];
	useRunOnce({
		fn: () => {
			setIsLoadingRandom(true);
			setIsLoadingCompare(true);
			const requestOptions = {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: null, //JSON.stringify({ title: 'React POST Request Example' })
			};

			fetch("/api/randomShowTopVoted.php", requestOptions)
				.then((response) => response.json())
				.then((data: RandomShowObject) => {
					fetch("/api/gettitle.php", {
						method: "POST",
						headers: {"Content-Type": "application/json"},
						body: JSON.stringify({show: data.CleanTitle}),
					})
						.then((response) => response.json())
						.then((showData: ShowObject) => {
							setRandomShowTopVoted(showData);
						});
				})
				.finally(() => {
					setIsLoadingRandom(false);
				});

			let promises: Promise<any>[] = [];
			for (var i = 0; i < 2; i++) {
				promises.push(
					new Promise((resolve, reject) => {
						fetch("/api/randomShowTopVoted.php", requestOptions)
							.then((response) => response.json())
							.then((data: RandomShowObject) => {
								fetch("/api/gettitle.php", {
									method: "POST",
									headers: {"Content-Type": "application/json"},
									body: JSON.stringify({show: data.CleanTitle}),
								})
									.then((response) => response.json())
									.then((showData: ShowObject) => {
										setCompareShows((old) => [...old, showData]);
										resolve(true);
									})
									.catch((c) => {
										reject(c);
									});
							});
					})
				);
			}
			Promise.all(promises).then((values) => {
				setIsLoadingCompare(false);
			});
		},
	});

	return (
		<Container maxWidth={false}>
			<Grid2
				container
				sx={{
					mx: {xs: 2, lg: 15},
					my: {xs: 2, lg: "auto"},
					minHeight: "95vh",
					display: "flex",
					flexGrow: 1,
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Grid2 xs={12} lg={4} sx={{alignContent: "center", justifyContent: "space-between"}}>
					<Paper sx={{p: 6, elevation: 5}}>
						<Typography sx={{mb: 4}} variant='h1' gutterBottom>
							Find out which show is better
						</Typography>
						{!isLoadingCompare ? (
							<>
								<Typography sx={{ml: 4, mb: 4}} variant='h2' gutterBottom>
									{compareShows.map((x) => `'${GetTitleFromShowTitle(x.ShowTitle)}'`).join(" vs. ")}
								</Typography>
								<Button
									component='a'
									href={`/compare?shows=${compareShows.map((x) => x.CleanTitle).join(",")}`}
									sx={{ml: 4}}
									variant='contained'
									color='primary'
								>
									Compare Shows
								</Button>
							</>
						) : (
							<Box component='div' sx={{display: "flex", alignContent: "center", justifyContent: "center", width: "100%"}}>
								<CircularProgress color='secondary' />
							</Box>
						)}
					</Paper>
					<Paper sx={{p: 6, elevation: 5}}>
						<Typography sx={{mb: 4}} variant='h1' gutterBottom>
							Satisfy your curiosity
						</Typography>
						{!isLoadingRandom && randomShowTopVoted ? (
							<>
								<Typography sx={{ml: 4, mb: 4}} variant='h2' gutterBottom>
									{hookText &&
										hookText[Math.floor(Math.random() * hookText.length)].replace(
											"SHOWNAME",
											GetTitleFromShowTitle(randomShowTopVoted.ShowTitle)
										)}
								</Typography>
								<Button component='a' href={`/${randomShowTopVoted.CleanTitle}`} sx={{ml: 4}} variant='contained' color='primary'>
									See the Report
								</Button>
							</>
						) : (
							<Box component='div' sx={{display: "flex", alignContent: "center", justifyContent: "center", width: "100%"}}>
								<CircularProgress color='secondary' />
							</Box>
						)}
					</Paper>
					<Paper sx={{p: 6, elevation: 5}}>
						<Typography sx={{mb: 4}} variant='h1' gutterBottom>
							...or check out some lists where we satisfied our own.
						</Typography>
						<Button component='a' href={`/curatedlists`} sx={{ml: 4}} variant='contained' color='primary'>
							View Cureated Lists
						</Button>{" "}
					</Paper>
				</Grid2>
				<Grid2 xs={12} lg={6} sx={{display: {xs: "none", lg: "flex"}, alignItems: "center"}}>
					<Box
						component='img'
						sx={{
							width: "100%",
						}}
						alt='person watching television'
						src='/images/splash.png'
					/>
				</Grid2>
			</Grid2>
		</Container>
	);
}
