import {Delete} from "@mui/icons-material";
import {
	Autocomplete,
	IconButton,
	Typography,
	TableRow,
	Table,
	TableCell,
	TableHead,
	Link,
	TableBody,
	TextField,
	Box,
	Chip,
	Backdrop,
	CircularProgress,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Container} from "@mui/system";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {RadarGraph} from "../Components/RadarGraph";
import {EpisodeObject, SearchResult, SeasonObject, ShowObject, ShowResult} from "../Interfaces/Api";
import useRunOnce from "../Tools/RunOnce";
import {GetColorFromRating, GetTitleFromShowTitle} from "../Tools/Tools";

export default function Compare(): JSX.Element {
	const [value, setValue] = useState<ShowResult[]>([]);
	const [inputValue, setInputValue] = useState<string>("");
	const [options, setOptions] = useState<readonly ShowResult[]>([]);
	const [compareShows, setCompareShows] = useState<ShowObject[]>([]);
	const [isCompareLaoding, setIsCompareLoading] = useState<boolean>(false);
	const firstColumnFontSize: string = "1.25rem";
	const [pageDescription, setPageDescription] = useState<string>("Visualize televsion show ratings, compare shows, browse curated lists, see genres.");
	const [pageTitle, setPageTitle] = useState<string>("What To Watch On TV -- Episode Ratings Visualizer");
	const removeCompare = (s: ShowObject): void => {
		setCompareShows(compareShows.filter((sf) => s !== sf));
		setValue(value?.filter((v) => v.Id !== s.Id));
	};

	const queryParams = new URLSearchParams(window.location.search);
	const shows = queryParams.get("shows");
	useRunOnce({
		fn: () => {
			//#region LOAD SHOW
			if (shows) {
				const showIds = shows.split(",");
				if (showIds && showIds.length > 0) {
					let promises: Promise<any>[] = [];
					showIds.map((showId: string) => {
						promises.push(
							new Promise((resolve, reject) => {
								fetch("/api/gettitle.php", {
									method: "POST",
									headers: {"Content-Type": "application/json"},
									body: JSON.stringify({
										show: showId,
									}),
								})
									.then((response) => {
										return response.json();
									})
									.then((data: ShowObject) => {
										if (data && data.Id) {
											resolve(data);
										}
									})
									.catch((e) => reject(e));
							})
						);
					});

					Promise.all(promises).then((values: ShowObject[]) => {
						let srs: ShowResult[] = [];
						values.map((s: ShowObject) => {
							srs.push({
								Id: s.Id,
								CleanTitle: s.CleanTitle,
								ShowTitle: s.ShowTitle,
								TotalAverage: s.TotalAverage,
								Votes: s.Votes,
							});
						});
						setValue(srs);
					});
				}
			}
		},
	});

	const getBestEpisode = (s: ShowObject): EpisodeObject => {
		return s.episodes.reduce((prev, current) => {
			return prev.Rating > current.Rating ? prev : current;
		});
	};

	const getWorstEpisode = (s: ShowObject): EpisodeObject => {
		return s.episodes.reduce((prev, current) => {
			return prev.Rating < current.Rating ? prev : current;
		});
	};

	const getBestSeason = (s: ShowObject): SeasonObject => {
		return s.seasons.reduce((prev, current) => {
			return prev.Rating > current.Rating ? prev : current;
		});
	};

	const getWorstSeason = (s: ShowObject): SeasonObject => {
		return s.seasons.reduce((prev, current) => {
			return prev.Rating < current.Rating ? prev : current;
		});
	};

	const searchShows = (query: string): void => {
		fetch("/api/titlesearch.php", {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({
				curshow: "",
				query: query,
				sortorder: "rating",
				minrating: 6,
				pageno: 1,
				pagesize: 20,
				stillrunning: false,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data: SearchResult) => {
				setOptions(data.ShowList.filter((show) => !options.includes(show)));
			});
	};

	const runCompare = (): void => {
		setIsCompareLoading(true);
		let promises: Promise<any>[] = [];

		if (value && value.length > 0) {
			value
				.filter((s) => !compareShows.map((s) => s.Id).includes(s.Id))
				.map((cs: ShowResult) => {
					promises.push(
						new Promise((resolve, reject) => {
							fetch("/api/gettitle.php", {
								method: "POST",
								headers: {"Content-Type": "application/json"},
								body: JSON.stringify({
									show: cs.CleanTitle,
								}),
							})
								.then((response) => {
									return response.json();
								})
								.then((data: ShowObject) => {
									setCompareShows((old) => [...old, data]);
									resolve(true);
								})
								.catch((c) => {
									reject(c);
								});
						})
					);
				});
		} else {
			setCompareShows([]);
		}

		Promise.all(promises).then(() => {
			setIsCompareLoading(false);
		});
	};

	useEffect(() => {
		if (inputValue && inputValue.length > 2) {
			searchShows(inputValue);
		} else {
			setOptions([]);
		}
	}, [inputValue]);

	useEffect(() => {
		if (value.length > 0) {
			runCompare();
			compareShows.map((s: ShowObject) => {
				if (!value.map((v) => v.Id).includes(s.Id)) {
					removeCompare(s);
				}
			});
			const urlParams = value.map((s) => s.CleanTitle).join(",");
			const compareShowsTitleString = value.map((s) => GetTitleFromShowTitle(s.ShowTitle)).join(" vs. ");
			const title = `Compare Shows: ${compareShowsTitleString}`;
			document.title = title;
			setPageTitle(title);
			setPageDescription(`Side-by-side comparisons of shows. ${compareShowsTitleString}`);
			window.history.pushState(`compare?shows=${urlParams}`, title, `/compare?shows=${urlParams}`);
		} else {
			// window.history.pushState(`compare`, document.title, `/compare`);
		}
	}, [value]);

	return (
		<Container maxWidth='xl'>
			{isCompareLaoding ? (
				<Backdrop open={true} sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}>
					<CircularProgress color='secondary' />
				</Backdrop>
			) : (
				<Grid2 spacing={4} sx={{justifyContent: "center", my: 2}}>
					<Grid2 xs={12}>
						<Autocomplete
							id='add-show-search'
							getOptionLabel={(option) => (typeof option === "string" ? option : option.ShowTitle)}
							multiple
							filterOptions={(x) => x}
							options={options}
							autoComplete
							filterSelectedOptions
							includeInputInList
							value={value}
							noOptionsText='No results'
							onChange={(event: any, newValue: ShowResult[] | null) => {
								if (newValue) {
									setValue(newValue);
									setInputValue("");
								}
							}}
							onInputChange={(event, newInputValue) => {
								setInputValue(newInputValue);
							}}
							renderInput={(params) => {
								return <TextField {...params} label='Add shows to compare' fullWidth />;
							}}
						/>
					</Grid2>
					<Grid2 xs={12}>
						{compareShows && compareShows.length > 0 && (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell></TableCell>
										{compareShows.map((s: ShowObject) => {
											return (
												<TableCell key={`compareshowtitles${s.Id}`} sx={{textAlign: "center", minWidth: 300, maxWidth: 300}}>
													<Typography variant='h3'>
														<Link href={`/${s.CleanTitle}`}>{GetTitleFromShowTitle(s.ShowTitle)}</Link>
														<IconButton onClick={() => removeCompare(s)} sx={{pl: 2, m: 0}} aria-label={"remove show from compare"}>
															<Delete />
														</IconButton>
													</Typography>
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell></TableCell>
										{compareShows.map((s: ShowObject) => {
											return (
												<TableCell key={`radarGraph${s.Id}`}>
													<RadarGraph showSeasonLabels={false} showObject={s} />
												</TableCell>
											);
										})}
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography sx={{fontSize: firstColumnFontSize}}>Seasons</Typography>
										</TableCell>
										{compareShows.map((s: ShowObject) => {
											return (
												<TableCell key={`seasonCount${s.Id}`}>
													<Typography sx={{textAlign: "center", fontSize: "1.5rem"}}>{`${s.SeasonCount}`}</Typography>
												</TableCell>
											);
										})}
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography sx={{fontSize: firstColumnFontSize}}>Episodes</Typography>
										</TableCell>
										{compareShows.map((s: ShowObject) => {
											return (
												<TableCell key={`episodeCount${s.Id}`}>
													<Typography sx={{textAlign: "center", fontSize: "1.5rem"}}>{`${s.episodes.length}`}</Typography>
												</TableCell>
											);
										})}
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography sx={{fontSize: firstColumnFontSize}}>Average Episode Rating</Typography>
										</TableCell>
										{compareShows.map((s: ShowObject) => {
											return (
												<TableCell key={`avgRatingEp${s.Id}`}>
													<Box sx={{flexGrow: 1, alignItems: "center", display: "flex", flexDirection: "column"}}>
														<Chip
															sx={{my: 1, backgroundColor: GetColorFromRating(getBestEpisode(s).Rating)}}
															label={`${(s.episodes.reduce((total, next) => total + next.Rating, 0) / s.episodes.length).toFixed(
																1
															)}`}
														/>
													</Box>
												</TableCell>
											);
										})}
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography sx={{fontSize: firstColumnFontSize}}>Best Episode</Typography>
										</TableCell>
										{compareShows.map((s: ShowObject) => {
											return (
												<TableCell key={`bestEp${s.Id}`}>
													<Box sx={{flexGrow: 1, alignItems: "center", display: "flex", flexDirection: "column"}}>
														<Typography sx={{textAlign: "center", fontSize: "1.5rem"}}>{getBestEpisode(s)?.EpisodeName}</Typography>
														<Chip
															sx={{my: 1, backgroundColor: GetColorFromRating(getBestEpisode(s).Rating)}}
															label={`${getBestEpisode(s).Rating.toFixed(1)}`}
														/>
													</Box>
												</TableCell>
											);
										})}
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography sx={{fontSize: firstColumnFontSize}}>Worst Episode</Typography>
										</TableCell>
										{compareShows.map((s: ShowObject) => {
											return (
												<TableCell key={`worstEp${s.Id}`}>
													<Box sx={{flexGrow: 1, alignItems: "center", display: "flex", flexDirection: "column"}}>
														<Typography sx={{textAlign: "center", fontSize: "1.5rem"}}>
															{getWorstEpisode(s)?.EpisodeName}
														</Typography>
														<Chip
															sx={{my: 1, backgroundColor: GetColorFromRating(getWorstEpisode(s).Rating)}}
															label={`${getWorstEpisode(s).Rating.toFixed(1)}`}
														/>
													</Box>
												</TableCell>
											);
										})}
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography sx={{fontSize: firstColumnFontSize}}>Best Season</Typography>
										</TableCell>
										{compareShows.map((s: ShowObject) => {
											return (
												<TableCell key={`bestSe${s.Id}`}>
													<Box sx={{flexGrow: 1, alignItems: "center", display: "flex", flexDirection: "column"}}>
														<Typography sx={{textAlign: "center", fontSize: "1.5rem"}}>{`Season ${
															getBestSeason(s)?.Season
														}`}</Typography>
														<Chip
															sx={{my: 1, backgroundColor: GetColorFromRating(getBestSeason(s).Rating)}}
															label={`${getBestSeason(s).Rating.toFixed(1)}`}
														/>
													</Box>
												</TableCell>
											);
										})}
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography sx={{fontSize: firstColumnFontSize}}>Worst Season</Typography>
										</TableCell>
										{compareShows.map((s: ShowObject) => {
											return (
												<TableCell key={`worstSe${s.Id}`}>
													<Box sx={{flexGrow: 1, alignItems: "center", display: "flex", flexDirection: "column"}}>
														<Typography sx={{textAlign: "center", fontSize: "1.5rem"}}>{`Season ${
															getWorstSeason(s)?.Season
														}`}</Typography>
														<Chip
															sx={{my: 1, backgroundColor: GetColorFromRating(getWorstSeason(s).Rating)}}
															label={`${getWorstSeason(s).Rating.toFixed(1)}`}
														/>
													</Box>
												</TableCell>
											);
										})}
									</TableRow>
								</TableBody>
							</Table>
						)}
					</Grid2>
				</Grid2>
			)}
		</Container>
	);
}
