import {Box, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {OmdbShow, ShowObject} from "../Interfaces/Api";
import {GetColorFromRating} from "../Tools/Tools";

export interface IShowSummaryProps {
	showObject: ShowObject;
	omdbObject: OmdbShow | undefined;
	isPrint?: boolean;
}

export function ShowSummary(props: IShowSummaryProps): JSX.Element {
	const {showObject, omdbObject, isPrint} = props;
	const theme = useTheme();
	const fontSize: string = isPrint ? "larger" : "inherit";
	const bestEpisode =
		showObject?.episodes?.length > 0
			? showObject.episodes.reduce((prev, current) => {
					return prev.Rating > current.Rating ? prev : current;
			  })
			: null;

	const worstEpisode =
		showObject?.episodes?.length > 0
			? showObject?.episodes.reduce((prev, current) => {
					return prev.Rating < current.Rating ? prev : current;
			  })
			: null;

	const bestSeason =
		showObject?.episodes?.length > 0
			? showObject.seasons.reduce((prev, current) => {
					return prev.Rating > current.Rating ? prev : current;
			  })
			: null;

	const worstSeason =
		showObject?.episodes?.length > 0
			? showObject.seasons.reduce((prev, current) => {
					return prev.Rating < current.Rating ? prev : current;
			  })
			: null;

	return (
		<Grid2
			container
			spacing={0}
			sx={{
				display: "flex",
				flexDirection: isPrint ? "row" : "column",
				justifyContent: isPrint ? "center" : "center",
				alignContent: isPrint ? "space-around" : "center",
			}}
		>
			<Grid2 xs={isPrint ? 4 : 12}>
				<Box
					component='div'
					sx={{
						backgroundBlendMode: "overlay, darken",
						background: `${theme.palette.background.default} url(${omdbObject?.Poster})`,
						backgroundSize: "cover",
						position: "relative",
						height: isPrint ? "100%" : {xs: 200, md: 300, lg: 400},
						p: 0,
					}}
				>
					<Box
						component='img'
						sx={{
							objectFit: "cover",
							maxHeight: "100%",
							maxWidth: "100%",
							height: "auto",
							width: "auto",
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%,-50%)",
						}}
						src={omdbObject?.Poster}
						alt='movie poster'
					/>
				</Box>
			</Grid2>
			<Grid2 xs={isPrint ? 6 : 12}>
				<TableContainer component={Paper}>
					<Table sx={{p: 2}}>
						<TableHead>
							<TableRow>
								<TableCell colSpan={2}>
									<Typography sx={{textAlign: "center"}} variant='h2'>
										{showObject.ShowTitle}
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{omdbObject && !isPrint && (
								<>
									<TableRow>
										<TableCell sx={{fontSize}}>Plot</TableCell>
										<TableCell sx={{fontSize}}>{omdbObject.Plot}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{fontSize}}>Awards</TableCell>
										<TableCell sx={{fontSize}}>{omdbObject.Awards}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{fontSize}}>Actors</TableCell>
										<TableCell sx={{fontSize}}>{omdbObject.Actors}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{fontSize}}>Writer</TableCell>
										<TableCell sx={{fontSize}}>{omdbObject.Writer}</TableCell>
									</TableRow>
								</>
							)}

							<TableRow>
								<TableCell sx={{fontSize}}>Seasons</TableCell>
								<TableCell sx={{fontSize}}>{`${showObject.SeasonCount}`}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell sx={{fontSize}}>Episodes</TableCell>
								<TableCell sx={{fontSize}}>{`${showObject.episodes.length}`}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell sx={{fontSize}}>Average Episode Rating</TableCell>
								<TableCell sx={{fontSize}}>
									{`${(showObject.episodes.reduce((total, next) => total + next.Rating, 0) / showObject.episodes.length).toFixed(1)}`}
								</TableCell>
							</TableRow>
							{bestEpisode && (
								<TableRow>
									<TableCell sx={{fontSize}}>Best Episode</TableCell>
									<TableCell sx={{fontSize}}>
										<Box component='div' sx={{display: "flex", flexDirection: "row", justifyContent: "left", alignContent: "center"}}>
											<Typography>{bestEpisode && <>{`${bestEpisode.EpisodeName ?? "Unknown"}`}</>}</Typography>
											<Chip
												sx={{ml: 2, backgroundColor: GetColorFromRating(bestEpisode.Rating)}}
												label={`${bestEpisode.Rating.toFixed(1)}`}
											/>
										</Box>
									</TableCell>
								</TableRow>
							)}
							{worstEpisode && (
								<TableRow>
									<TableCell sx={{fontSize}}>Worst Episode</TableCell>
									<TableCell sx={{fontSize}}>
										<Box component='div' sx={{display: "flex", flexDirection: "row", justifyContent: "left", alignContent: "center"}}>
											<Typography>{worstEpisode && <>{`${worstEpisode.EpisodeName ?? "Unknown"}`}</>}</Typography>
											<Chip
												sx={{ml: 2, backgroundColor: GetColorFromRating(worstEpisode.Rating)}}
												label={`${worstEpisode.Rating.toFixed(1)}`}
											/>
										</Box>
									</TableCell>
								</TableRow>
							)}
							{bestSeason && (
								<TableRow>
									<TableCell sx={{fontSize}}>Best Season</TableCell>
									<TableCell sx={{fontSize}}>
										<Box component='div' sx={{display: "flex", flexDirection: "row", justifyContent: "left", alignContent: "center"}}>
											<Typography>{bestSeason && <>{`Season ${bestSeason.Season ?? "Unknown"}`}</>}</Typography>
											<Chip
												sx={{ml: 2, backgroundColor: GetColorFromRating(bestSeason.Rating)}}
												label={`${bestSeason.Rating.toFixed(1)}`}
											/>
										</Box>
									</TableCell>
								</TableRow>
							)}
							{worstSeason && (
								<TableRow>
									<TableCell sx={{fontSize}}>Worst Season</TableCell>
									<TableCell sx={{fontSize}}>
										<Box component='div' sx={{display: "flex", flexDirection: "row", justifyContent: "left", alignContent: "center"}}>
											<Typography>{worstSeason && <>{`Season ${worstSeason.Season ?? "Unknown"}`}</>}</Typography>
											<Chip
												sx={{ml: 2, backgroundColor: GetColorFromRating(worstSeason.Rating)}}
												label={`${worstSeason.Rating.toFixed(1)}`}
											/>
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid2>
		</Grid2>
	);
}
