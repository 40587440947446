import {
	ClickAwayListener,
	Tooltip,
	Typography,
	styled,
	tooltipClasses,
	TooltipProps,
	Chip,
	Card,
	CardHeader,
	CardContent,
	TableRow,
	Table,
	TableCell,
	TableBody,
} from "@mui/material";
import React from "react";
import {EpisodeObject} from "../Interfaces/Api";
import {GetColorFromRating} from "../Tools/Tools";
export interface IShowToolTipProps {
	episodeObject: EpisodeObject;
	hideRating: boolean;
}
export function ShowToolTip(props: IShowToolTipProps): JSX.Element {
	const {episodeObject, hideRating} = props;
	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	const HtmlTooltip = styled(({className, ...props}: TooltipProps) => <Tooltip {...props} classes={{popper: className}} />)(({theme}) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			color: "inherit",
			maxWidth: 320,
			border: "1px solid #333",
		},
	}));

	return episodeObject ? (
		<ClickAwayListener key={`showToolTip${episodeObject.Id}`} onClickAway={handleTooltipClose}>
			<div>
				<HtmlTooltip
					title={
						<Card>
							<CardHeader
								sx={{py: 2}}
								action={
									<Chip
										sx={{alignSelf: "top", textAlign: "right", ml: 2, backgroundColor: GetColorFromRating(episodeObject.Rating)}}
										label={episodeObject?.Rating?.toFixed(1)}
									/>
								}
								title={`${episodeObject.EpisodeName}${episodeObject.IsTopRated === 1 ? " ★" : ""}`}
							/>
							<CardContent sx={{p: 2}}>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell sx={{py: 1}}>Season</TableCell>
											<TableCell sx={{py: 1}}>{episodeObject.Season}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{py: 1}}>Episode</TableCell>
											<TableCell sx={{py: 1}}>{episodeObject.Episode}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{py: 1}}>Runtime</TableCell>
											<TableCell sx={{py: 1}}>{episodeObject.RunTimeMinutes}m</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</CardContent>
						</Card>
					}
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
				>
					<Typography onClick={handleTooltipOpen} variant='body1'>
						{!hideRating && episodeObject.Rating.toFixed(1)}
					</Typography>
				</HtmlTooltip>
			</div>
		</ClickAwayListener>
	) : (
		<></>
	);
}
