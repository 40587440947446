import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {Routes, Route} from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import CuratedLists from "./Pages/List";
import Compare from "./Pages/Compare";
import GenreList from "./Pages/GenreList";
import ResponsiveAppBar from "./Components/ResponsiveAppBar";
import Show from "./Pages/Show";
import {Genre} from "./Pages/Genre";
import {HelmetProvider} from "react-helmet-async";
import CrashBurn from "./Pages/Lists/CrashBurn";
import OneHit from "./Pages/Lists/OneHit";
import Juggernaut from "./Pages/Lists/Juggernaut";
import Steady from "./Pages/Lists/Steady";
import NoStinkers from "./Pages/Lists/NoStinkers";

export default function App() {
	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

	const theme = React.useMemo(
		() =>
			createTheme({
				palette: {
					mode: prefersDarkMode ? "dark" : "light",
					primary: {
						main: "#dfba69",
					},
					secondary: {
						main: "#5a2e2e",
					},
					text: {
						primary: prefersDarkMode ? "#fffedf" : "#0000c0",
					},
				},
				typography: {
					fontFamily: [
						"-apple-system",
						"BlinkMacSystemFont",
						'"Segoe UI"',
						"Roboto",
						'"Helvetica Neue"',
						"Arial",
						"sans-serif",
						'"Apple Color Emoji"',
						'"Segoe UI Emoji"',
						'"Segoe UI Symbol"',
					].join(","),
					h1: {
						fontSize: "2rem",
						fontWeight: "300",
					},
					h2: {
						fontSize: "1.4rem",
					},
					h3: {
						fontSize: "1.3rem",
					},
					h4: {
						fontSize: "1.2rem",
					},
				},
			}),
		[prefersDarkMode]
	);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<ResponsiveAppBar></ResponsiveAppBar>

			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/:showId' element={<Show />} />
				<Route path='/genrelist' element={<GenreList />} />
				<Route path='/curatedlists' element={<CuratedLists />} />
				<Route path='/about' element={<About />} />
				<Route path='/compare' element={<Compare />} />
				<Route path='/lists/crash-and-burn' element={<CrashBurn />} />
				<Route path='/lists/one-hit-wonder' element={<OneHit />} />
				<Route path='/lists/steady' element={<Steady />} />
				<Route path='/lists/no-stinkers' element={<NoStinkers />} />
				<Route path='/lists/juggernaut' element={<Juggernaut />} />
				<Route path='/genres/:genreName' element={<Genre />} />
			</Routes>
		</ThemeProvider>
	);
}
