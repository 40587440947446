import {EpisodeObject, SeasonObject, ShowObject} from "../Interfaces/Api";
import {lighten, useTheme} from "@mui/material/styles";
import {GetColorFromRating, GetTextColorFromRating} from "../Tools/Tools";

export interface IRadarGraphProps {
	showObject: ShowObject;
	showSeasonLabels: boolean;
}

export interface IPointCoordinate {
	x: number;
	y: number;
}

export function RadarGraph(props: IRadarGraphProps): JSX.Element {
	const theme = useTheme();
	const {showObject, showSeasonLabels} = props;
	const maxSizePct: number = 0.9;
	const RadarRadius: number = 50;

	const DescribeArc = (centerX: number, centerY: number, radius: number, startAngle: number, endAngle: number, includeLines: boolean = false): string => {
		const start = PolarToCartesian(centerX, centerY, radius, endAngle);
		const end = PolarToCartesian(centerX, centerY, radius, startAngle);

		const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		const addLines = `L ${centerX} ${centerX} L ${start.x} ${start.y} Z`;
		const d = `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y} ${!includeLines ? "" : addLines}`;

		return d;
	};

	const GetWedge = (centerX: number, centerY: number, radius: number, startAngle: number, endAngle: number, includeLines: boolean = false): string => {
		const start = PolarToCartesian(centerX, centerY, radius, endAngle);
		const end = PolarToCartesian(centerX, centerY, radius, startAngle);

		const d = `M ${start.x} ${start.y} ${centerX} ${centerY} ${end.x} ${end.y}`;

		return d;
	};

	const PolarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number): IPointCoordinate => {
		let angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

		let ptc = {
			x: centerX + radius * Math.cos(angleInRadians),
			y: centerY + radius * Math.sin(angleInRadians),
		};
		return ptc;
	};

	const getRadarSeasonSegments = (): JSX.Element[] => {
		let prevAngle = 0;
		let elems: JSX.Element[] = [];
		showObject.seasons
			.sort((a, b) => (a.Season > b.Season ? 1 : a.Season < b.Season ? -1 : 0))
			.map((s: SeasonObject, i: number) => {
				const rs = getRadarSeasonSegment(s, i, prevAngle);
				prevAngle = rs.prevAngle;
				return elems.push(rs.ele);
			}, prevAngle);
		return elems;
	};

	const getRadarSeasonSegment = (s: SeasonObject, i: number, prevAngle: number): {ele: JSX.Element; prevAngle: number} => {
		const epCount = showObject.episodes.filter((x) => x.Season === s.Season).length;
		const seasonAngle = 360 * (epCount / showObject.episodes.length);
		const startAngle = prevAngle;
		const endAngle = prevAngle + seasonAngle;
		prevAngle = endAngle;
		const seasonDLabel = DescribeArc(RadarRadius, RadarRadius, RadarRadius * maxSizePct, startAngle, endAngle, false);
		const seasonDLine = DescribeArc(RadarRadius, RadarRadius, RadarRadius * (s.Rating * 0.1) * maxSizePct, startAngle, endAngle, false);
		const seasonTitleLine = GetWedge(RadarRadius, RadarRadius, RadarRadius * maxSizePct, startAngle, endAngle, true);
		const seasonRating: string = GetColorFromRating(s.Rating);
		const ele: JSX.Element = (
			<g key={`seasongroup${s.Season}`}>
				<path
					stroke={GetColorFromRating(10)}
					d={seasonDLabel}
					fill='none'
					vectorEffect='non-scaling-stroke'
					id={`seasonAvgPathLabel${showObject.Id}${s.Season}`}
				></path>
				<path
					strokeDasharray='2'
					strokeWidth={0.5}
					stroke={seasonRating}
					fill='none'
					d={seasonDLine}
					markerStart='url(#arrowhead)'
					vectorEffect='non-scaling-stroke'
				></path>
				<text dy='1' dx='-1' fill={showSeasonLabels ? theme.palette.primary.dark : "none"} fontSize='2' strokeWidth={0}>
					<textPath startOffset='50%' alignmentBaseline='hanging' href={`#seasonAvgPathLabel${showObject.Id}${s.Season}`}>
						{`S${s.Season}`}
					</textPath>
				</text>
				<path
					strokeDasharray={2}
					strokeWidth={0.5}
					stroke={GetColorFromRating(s.Rating)}
					fill='none'
					d={seasonTitleLine}
					vectorEffect='non-scaling-stroke'
				></path>
			</g>
		);
		return {ele, prevAngle};
	};

	const getRadarGraphSegment = (ep: EpisodeObject, i: number): JSX.Element => {
		var lineLength = RadarRadius - (ep.Rating / 10) * RadarRadius * maxSizePct;
		var strokeColor = GetColorFromRating(ep.Rating);
		var rotationDegrees = (i / showObject.episodes.length) * 360;
		var nextEp = showObject.episodes.sort((a, b) => {
			if (a.Season == b.Season) {
				return a.Episode < b.Episode ? -1 : 1;
			} else {
				return a.Season < b.Season ? -1 : 1;
			}
		})[i + 1 === showObject.episodes.length ? 0 : i + 1];
		var nextEpLineLength = (nextEp.Rating / 10) * RadarRadius * maxSizePct;
		var nextEpStrokeColor: string = GetColorFromRating(nextEp.Rating);
		var nextEpEndPoint = PolarToCartesian(RadarRadius, RadarRadius, nextEpLineLength, 360 / showObject.episodes.length);
		var d = `M ${RadarRadius},${RadarRadius} L ${RadarRadius},${lineLength} L ${nextEpEndPoint.x},${nextEpEndPoint.y} L ${RadarRadius},${RadarRadius}`;
		var lgId = `lgradar${showObject.Id}${i}`;

		return (
			<g key={`epgroup${ep.Episode}${ep.Season}`}>
				<linearGradient
					id={lgId}
					gradientUnits='objectBoundingBox'
					x1='0'
					y1='0'
					x2='1'
					y2='1'
					gradientTransform={`rotate(${rotationDegrees} 0.5 0.5)`}
				>
					<stop offset='0%' stopColor={strokeColor} stopOpacity='1' />
					<stop offset='100%' stopColor={nextEpStrokeColor} stopOpacity='1' />
				</linearGradient>
				<g id={`radarGroup${showObject.Id}${i}`}>
					<animateTransform
						attributeType='xml'
						attributeName='transform'
						type='rotate'
						from={`0 ${RadarRadius} ${RadarRadius}`}
						to={`${rotationDegrees} ${RadarRadius} ${RadarRadius}`}
						dur='1s'
						additive='sum'
						fill='freeze'
						repeatCount='0'
					/>
					<line
						opacity='1.0'
						strokeWidth='.1'
						strokeLinecap='round'
						stroke={i === 0 ? "#ffffff" : GetTextColorFromRating(ep.Rating)}
						x1={RadarRadius}
						y1={RadarRadius}
						x2={RadarRadius}
						y2={RadarRadius - (ep.Rating / 10) * RadarRadius * maxSizePct}
					></line>
					<line
						opacity='1.0'
						strokeWidth='.1'
						strokeLinecap='round'
						stroke={GetTextColorFromRating(ep.Rating)}
						x1={RadarRadius}
						y1={RadarRadius - (ep.Rating / 10) * RadarRadius * maxSizePct}
						x2={nextEpEndPoint.x}
						y2={nextEpEndPoint.y}
					></line>
					<line
						opacity='1.0'
						strokeWidth='.1'
						strokeLinecap='round'
						stroke={i + 1 === showObject.episodes.length ? "#ffffff" : nextEpStrokeColor}
						x1={nextEpEndPoint.x}
						y1={nextEpEndPoint.y}
						x2={RadarRadius}
						y2={RadarRadius}
					></line>
					<path opacity='1.0' strokeWidth='.1' fill={`url(#${lgId})`} d={d} vectorEffect='non-scaling-stroke'></path>
				</g>
			</g>
		);
	};

	const getRatingKey = (): JSX.Element => {
		const elems: JSX.Element[] = [];
		for (var i = 10; i >= 4; i--) {
			elems.push(
				<g id={`ratingKey`}>
					<circle
						strokeWidth='.25'
						id={`ratingKey${i}}`}
						r={RadarRadius * (i * 0.1) * maxSizePct}
						cx={RadarRadius}
						cy={RadarRadius}
						fill={lighten(GetColorFromRating(i), 0.1)}
					></circle>
				</g>
			);
		}
		return <g key='ratingKeyGroup'>{elems}</g>;
	};
	return (
		<svg className="radarGraph" id={`radarGraph${showObject.Id}`} width='100%' height='100%' viewBox='0 0 100 100' alignmentBaseline='central'>
			<defs>
				<marker id='arrowhead' markerWidth='20' markerHeight='20' refX='20' refY='10' orient='auto-start-reverse'>
					<polygon fill={theme.palette.primary.dark} points='0 0, 20 10, 0 20' />
				</marker>
			</defs>

			{showObject.episodes
				.sort((a, b) => {
					if (a.Season == b.Season) {
						return a.Episode < b.Episode ? -1 : 1;
					} else {
						return a.Season < b.Season ? -1 : 1;
					}
				})
				.map((ep: EpisodeObject, i: number) => {
					return getRadarGraphSegment(ep, i);
				})}
			{getRadarSeasonSegments()}
			<circle
				strokeWidth='.25'
				id={`seriesAverage${showObject.Id}`}
				r={RadarRadius * (showObject.TotalAverage * 0.1) * maxSizePct}
				cx={RadarRadius}
				cy={RadarRadius}
				fill='none'
				strokeDasharray='8'
			></circle>
		</svg>
	);
}
