import {Card, CardHeader, Typography, CardMedia, CardContent, CardActions, Button, Link} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Container} from "@mui/system";
import {Helmet} from "react-helmet-async";

export default function CuratedLists(): JSX.Element {
	return (
		<Container maxWidth='lg'>
			<h2>Lists</h2>
			<p>
				These lists are dynamically generated based on certain rules. If you have an idea for a new list, let me know at{" "}
				<Link href="mailto:feedback@whattowatchon.tv?subject='List Idea'" underline='hover' target='_blank' rel='noreferrer' color='inherit'>
					feedback@whattowatchon.tv
				</Link>
			</p>
			<Grid2 container spacing={3} sx={{alignContent: "center"}}>
				<Grid2 xs={12} sm={6} md={4}>
					<Card>
						<CardHeader
							title={
								<Typography sx={{textAlign: "center"}} variant='h3'>
									Crash and Burn
								</Typography>
							}
						></CardHeader>
						<CardMedia component='img' src='/images/burn.jpg' height='200'></CardMedia>
						<CardContent>
							<Typography variant='body2'>You either die a hero...</Typography>
							<Typography sx={{mt: 2}} variant='body1'>
								These shows had a final season that was at least one rating point lower than the average of the previous seasons.
							</Typography>
						</CardContent>
						<CardActions sx={{display: "flex", alignContent: "flex-end"}}>
							<Button component='a' href={`/lists/crash-and-burn`}>
								View
							</Button>
						</CardActions>
					</Card>
				</Grid2>
				<Grid2 xs={12} sm={6} md={4}>
					<Card>
						<CardHeader
							title={
								<Typography sx={{textAlign: "center"}} variant='h3'>
									One Hit Wonder
								</Typography>
							}
						></CardHeader>
						<CardMedia component='img' src='/images/one-hit.jpg' height='200'></CardMedia>
						<CardContent>
							<Typography variant='body2'>Season 2 is coming...right?</Typography>
							<Typography sx={{mt: 2}} variant='body1'>
								Shows with a single season rated above 8.5.
							</Typography>
						</CardContent>
						<CardActions sx={{display: "flex", alignContent: "flex-end"}}>
							<Button component='a' href={`/lists/one-hit-wonder`}>
								View
							</Button>
						</CardActions>
					</Card>
				</Grid2>
				<Grid2 xs={12} sm={6} md={4}>
					<Card>
						<CardHeader
							title={
								<Typography sx={{textAlign: "center"}} variant='h3'>
									Juggernauts
								</Typography>
							}
						></CardHeader>
						<CardMedia component='img' src='/images/juggernaut.jpg' height='200'></CardMedia>
						<CardContent>
							<Typography variant='body2'>Don't you know who I am?</Typography>
							<Typography sx={{mt: 2}} variant='body1'>
								Shows that ran (or are still running) for at least 20 years.
							</Typography>
						</CardContent>
						<CardActions sx={{display: "flex", alignContent: "flex-end"}}>
							<Button component='a' href={`/lists/juggernaut`}>
								View
							</Button>
						</CardActions>
					</Card>
				</Grid2>
				<Grid2 xs={12} sm={6} md={4}>
					<Card>
						<CardHeader
							title={
								<Typography sx={{textAlign: "center"}} variant='h3'>
									Steady
								</Typography>
							}
						></CardHeader>
						<CardMedia component='img' src='/images/steady.jpg' height='200'></CardMedia>
						<CardContent>
							<Typography variant='body2'>Never disappointing.</Typography>
							<Typography sx={{mt: 2}} variant='body1'>
								These shows never dipped below a rating of 8.5 for any season.
							</Typography>
						</CardContent>
						<CardActions sx={{display: "flex", alignContent: "flex-end"}}>
							<Button component='a' href={`/lists/steady`}>
								View
							</Button>
						</CardActions>
					</Card>
				</Grid2>
				<Grid2 xs={12} sm={6} md={4}>
					<Card>
						<CardHeader
							title={
								<Typography sx={{textAlign: "center"}} variant='h3'>
									No Stinkers
								</Typography>
							}
						></CardHeader>
						<CardMedia component='img' src='/images/no-stinkers.jpg' height='200'></CardMedia>
						<CardContent>
							<Typography variant='body2'>Every episode is like a summer's breeze.</Typography>
							<Typography sx={{mt: 2}} variant='body1'>
								These shows clocked at least 50 episodes, without a single one ever rating below 7.5.
							</Typography>
						</CardContent>
						<CardActions sx={{display: "flex", alignContent: "flex-end"}}>
							<Button component='a' href={`/lists/no-stinkers`}>
								View
							</Button>
						</CardActions>
					</Card>
				</Grid2>
			</Grid2>
		</Container>
	);
}
