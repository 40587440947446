import {Help} from "@mui/icons-material";
import {
	Container,
	Backdrop,
	CircularProgress,
	Table,
	Typography,
	Tooltip,
	IconButton,
	TableContainer,
	TableHead,
	TableBody,
	Paper,
	TableCell,
	TableRow,
	Link,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {useParams} from "react-router-dom";
import {GenreTopThreeCard} from "../Components/GenreTopThreeCard";
import {GenreListItem} from "../Interfaces/Api";
import useRunOnce from "../Tools/RunOnce";
import {ConvertToProperCase} from "../Tools/Tools";

export function Genre(): JSX.Element {
	let {genreName} = useParams();
	const [isLoading, setIsLoading] = useState<boolean>();
	const [genreItems, setGenreItems] = useState<GenreListItem[]>([]);
	const [topThree, setTopThree] = useState<GenreListItem[]>([]);
	const pageDescription: string = `List of shows in the genre ${genreName}.`;
	const pageTitle: string = `Genres -- ${genreName}`;

	const minLeaderboardVotes: number = 100000;
	useRunOnce({
		fn: () => {
			//#region LOAD GENRE
			setIsLoading(true);

			fetch("/api/genreList.php", {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify({genre: genreName}),
			})
				.then((response) => response.json())
				.then((data: GenreListItem[]) => {
					setGenreItems(data);
				})
				.finally(() => {
					setIsLoading(false);
				});
			//#endregion
		},
	});

	useEffect(() => {
		if (genreItems) {
			setTopThree(genreItems.filter((x) => x.votes > minLeaderboardVotes).slice(0, 3));
		}
	}, [genreItems]);

	return (
		<Container maxWidth='lg'>
			{isLoading ? (
				<Backdrop open={true} sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}>
					<CircularProgress color='secondary' />
				</Backdrop>
			) : genreItems && genreItems.length > 0 ? (
				<Grid2 container spacing={2}>
					<Grid2 xs={12} sx={{display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center"}}>
						<Typography variant='h1' sx={{my: 2, textAlign: "center"}}>
							{`${ConvertToProperCase(genreName)} Leaderboard`}
						</Typography>
						<Tooltip title={`A show must have at least ${minLeaderboardVotes.toLocaleString("en-US")} votes to make the leaderboard`}>
							<IconButton>
								<Help />
							</IconButton>
						</Tooltip>
					</Grid2>
					<Grid2 xs={12}>
						<Grid2 container spacing={3}>
							{topThree &&
								topThree.length > 0 &&
								topThree.map((gi: GenreListItem, i: number) => {
									return (
										<Grid2 xs={12} md={4} key={`topThree${i}`}>
											<GenreTopThreeCard item={gi} index={i}></GenreTopThreeCard>
										</Grid2>
									);
								})}
						</Grid2>
					</Grid2>
					<Grid2 xs={12}>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Rank</TableCell>
										<TableCell>Title</TableCell>
										<TableCell>Rating</TableCell>
										<TableCell>Votes</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{genreItems.map((gi: GenreListItem, index: number) => {
										return (
											<TableRow key={`genreRow${index}`}>
												<TableCell>{index + 1}</TableCell>
												<TableCell>
													<Link href={`/${gi.cleantitle}`} underline='hover' target='_self' color='inherit'>
														{gi.title}
													</Link>
												</TableCell>
												<TableCell>{gi.rating.toFixed(1)}</TableCell>
												<TableCell>{gi.votes.toLocaleString("en-us")}</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid2>
				</Grid2>
			) : (
				<Typography variant='h3'>{`No known genre: ${genreName}`}</Typography>
			)}
		</Container>
	);
}
